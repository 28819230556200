<template>
  <div class='table_filter flex-start'>
    <div>

      <span class='demonstration'>{{ $sys('mall.dateRange') }}</span>

      <el-date-picker
        v-model='dateVal'
        :clearable='false'
        :picker-options='{
            disabledDate(date) {
              return date > pcDateRange.max || date < pcDateRange.min
            }
        }'
        type='daterange'
        :start-placeholder="$sys('mall.dateBegin')"
        :end-placeholder="$sys('mall.dateEnd')"
        @focus='handleFocus'
        @blur='handleBlur'
        @change='handleChange'
      >
      </el-date-picker>

      <el-button type='primary' @click='handleConfirm()' style='margin-left:10px;'>{{ $sys('btn.confirm') }}</el-button>

      <div class='tips'>*{{ $sys('warn.recordTips') }}</div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DatePicker, Button } from 'element-ui'

export default {
  props: {
    defaultDate: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isFirstFocus: true,
      dateVal: []
    }
  },
  created() {
    this.dateParams = []
    this.dateVal = this.defaultDate
  },
  components: {
    [DatePicker.name]: DatePicker,
    [Button.name]: Button
  },
  computed: {
    ...mapState('order', ['orderSearch', 'dateRange']),
    ...mapGetters(['lang']),
    pcDateRange() {
      return {
        max: this.dateRange.max,
        min: this.dateRange.min.setDate(this.dateRange.min.getDate() - 1)
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('onSearch', this.dateParams)
    },
    handleFocus() {
      this.isFirstFocus && this.$emit('onBegin')

      this.isFirstFocus = false
    },
    handleBlur() {
      this.isFirstFocus = true
    },
    handleChange(val) {
      this.dateParams = val
    }
  },
  watch: {
    lang(val) {
      this.dateVal = []
      this.$nextTick(() => {
        this.dateVal = this.defaultDate
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.table_filter {
  align-items: flex-start;
  margin-bottom: 10px;
}

.filter_type {
  margin-right: 20px;
}

.demonstration {
  margin-right: 10px;
}

.tips {
  font-size: 12px;
  color: red;
  margin: 10px 80px;
  margin-left: 140px;
}
</style>